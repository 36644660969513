///
/// @file standalone/shared/_animations.scss
///

// standalone shared animations
@mixin shimmer($duration: 1s, $easing: linear) {
  animation: elc-shimmer $duration 60 $easing;
  backface-visibility: hidden;
  background: $color-shimmer-grey-light
    linear-gradient(
      -45deg,
      $color-shimmer-grey-light 0%,
      $color-shimmer-grey-light 40%,
      $color-shimmer-grey 50%,
      $color-shimmer-grey-light 60%,
      $color-shimmer-grey-light 100%
    );
  background-size: 250% 250%;
  perspective: 1000px;
  transform: rotateZ(360deg);
  will-change: transform;
  [dir='rtl'] & {
    animation: elc-shimmer-rtl $duration 60 $easing;
    backface-visibility: hidden;
    background: $color-shimmer-grey-light
      linear-gradient(
        45deg,
        $color-shimmer-grey-light 0%,
        $color-shimmer-grey-light 40%,
        $color-shimmer-grey 50%,
        $color-shimmer-grey-light 60%,
        $color-shimmer-grey-light 100%
      );
    background-size: 250% 250%;
    perspective: 1000px;
    transform: rotateZ(360deg);
    will-change: transform;
  }
}

@mixin fade-in($duration: 1s, $easing: linear) {
  animation: fade-in $duration $easing forwards;
}

/// standalone shared keyframes animation
@keyframes elc-shimmer {
  0% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes elc-shimmer-rtl {
  0% {
    background-position: bottom left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
