@import '../theme-bootstrap';
@import './shared/animations';

// Product Brief Wrapper fade-in
.elc-product-brief-wrapper {
  @include fade-in(0.5s, ease-out);
  opacity: 0;
}

// Product Grid fade-in
.sd-product-grid {
  position: relative;
  [data-component='product-grid'] {
    height: 100%;
    width: 100%;
    opacity: 0;
    overflow-y: hidden;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    &.sd-mounted {
      @include fade-in(0.25s, ease-in-out);
      height: auto;
      overflow-y: visible;
      pointer-events: all;
      position: relative;
      visibility: visible;
      .elc-product-carousel-slider-view,
      .slick-slider {
        width: 100%;
      }
      &.sd-transition {
        position: absolute;
        width: 100%;
        right: 0;
        left: 0;
        & ~ .sd-mount-skeleton {
          position: relative;
        }
      }
      & ~ .sd-mount-skeleton {
        position: absolute;
      }
    }
  }
}

// Only for MPP
.field-elc-mpp-content {
  .sd-product-grid {
    [data-component='product-grid']:not(.sd-mounted) {
      .elc-product-images-wrapper:not(.lazyloaded) {
        @include shimmer(1.5s, ease-in-out);
      }
    }
    .elc-check-visibility {
      .elc-product-images-wrapper {
        animation: none;
      }
    }
  }
}

// Skeleton WRAPPER
.sd-mount-skeleton {
  position: relative;

  // Skeleton shared style

  .skeleton {
    &-box,
    &-bar {
      @include shimmer(1.5s, ease-in-out);
    }
    &-box {
      padding-top: 100%;
    }
    &-bar {
      height: 30px;
      @for $i from 1 through 10 {
        &--#{$i}0 {
          width: #{$i}0%;
        }
        &--top-#{$i}0 {
          margin-top: #{$i}0px;
        }
      }
    }
  }
}

// Skeleton GRID
.sd-grid-skeleton {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  & &-filters {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px 16px 10px;
    .skeleton-bar {
      height: 40px;
      max-width: 230px;
    }
  }
  &-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-auto-flow: dense;
    gap: 0;
    margin-top: 20px;
    width: 100%;
    &.skeleton-mobile-1,
    &.skeleton-desktop-1,
    &.skeleton-largescreen-1,
    &.skeleton-extralargescreen-1 {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      &.is-slider {
        .skeleton-item {
          display: none;
          &:nth-of-type(-n + 1) {
            display: block;
          }
        }
      }
    }
    @for $i from 2 through 8 {
      &.skeleton-mobile-#{$i} {
        grid-template-columns: repeat(auto-fill, minmax(#{100% / $i}, 1fr));
        &.is-slider {
          .skeleton-item {
            display: none;
            &:nth-of-type(-n + #{$i}) {
              display: block;
            }
          }
        }
      }
      &.skeleton-desktop-#{$i} {
        @include breakpoint($medium-up) {
          grid-template-columns: repeat(auto-fill, minmax(#{100% / $i}, 1fr));
          &.is-slider {
            .skeleton-item {
              display: none;
              &:nth-of-type(-n + #{$i}) {
                display: block;
              }
            }
          }
        }
      }
      &.skeleton-largescreen-#{$i} {
        @include breakpoint($landscape-up) {
          grid-template-columns: repeat(auto-fill, minmax(#{100% / $i}, 1fr));
          &.is-slider {
            .skeleton-item {
              display: none;
              &:nth-of-type(-n + #{$i}) {
                display: block;
              }
            }
          }
        }
      }
      &.skeleton-extralargescreen-#{$i} {
        @include breakpoint($extra-large-up) {
          grid-template-columns: repeat(auto-fill, minmax(#{100% / $i}, 1fr));
          &.is-slider {
            .skeleton-item {
              display: none;
              &:nth-of-type(-n + #{$i}) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .skeleton {
    &-item {
      width: 100%;
      padding: 16px 16px 60px;
      grid-column-end: span 1;
      grid-row-end: span 1;
      &.is-featured {
        @include breakpoint($medium-up) {
          grid-column-end: span 2;
          grid-row-end: span 2;
        }
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .elc-product-rating-wrapper {
    display: flex;
  }
  .elc-stars-wrapper {
    margin: 0;
    display: inline-block;
    position: relative;
    padding: 0;
    user-select: none;
    font-size: 24px;
    font-weight: bold;
    height: 14px;
  }
  .elc-stars-simplified,
  .elc-stars-simplified-stars {
    display: inline-block;
    margin-inline-end: 8px;
  }
  .elc-rating {
    display: flex;
    align-items: end;
    line-height: 0;
  }
  .elc-rating-reviews {
    display: inline-block;
    margin: 0 8px;
    vertical-align: middle;
  }
}

// Product Full fade-in
.sd-product-spp {
  [data-component='product-full'] {
    height: 0;
    opacity: 0;
    overflow-y: hidden;
    pointer-events: none;
    visibility: hidden;
    &.sd-mounted {
      @include fade-in(0.25s, ease-in-out);
      height: auto;
      overflow-y: visible;
      pointer-events: all;
      visibility: visible;
    }
  }
}

// Skeleton Product Full
.sd-full-skeleton {
  display: flex;
  justify-content: center;
  .skeleton {
    &-full {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      @include breakpoint($medium-up) {
        flex-direction: row;
      }
    }
    &-column {
      &--image {
        margin: 0 auto;
        @include breakpoint($medium-up) {
          padding: 0 8px;
        }
      }
      &--details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 8px;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 40%;
        }
      }
    }
    &-image-wrapper {
      margin: 0 auto;
      .elc-img {
        filter: blur(5px);
        height: auto;
      }
    }
  }
}

.sd-product-spp__product-full {
  position: relative;
  [data-component='product-full'] {
    height: 100%;
    width: 100%;
    opacity: 0;
    overflow-y: hidden;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    &.sd-mounted {
      @include fade-in(0.25s, ease-in-out);
      height: auto;
      overflow-y: visible;
      pointer-events: all;
      position: relative;
      visibility: visible;
      .elc-product-carousel-slider-view,
      .slick-slider {
        width: 100%;
      }
      &.sd-transition {
        position: absolute;
        width: 100%;
        #{$rdirection}: 0;
        #{$ldirection}: 0;
        & ~ .sd-mount-skeleton {
          position: relative;
        }
      }
      & ~ .sd-mount-skeleton {
        position: absolute;
      }
    }
  }
}
